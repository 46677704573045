import routes from './routes'
import stelace from 'hc-core/composables/stelace'
import { route } from 'quasar/wrappers'
import { createCookieInstance } from 'hc-core/composables/auth'
import { base64Decoder, base64Encoder } from 'hc-core/composables/routing'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
export default route(function ({ store, ssrContext }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  if (process.env.CLIENT) {
    Router.beforeEach(async (to, from, next) => {
      try {
        const happycabCookie = createCookieInstance({ ssrContext }).getHappyCookie()
        const isAuthenticated = stelace.auth.info().isAuthenticated
        if (isAuthenticated && !store.getters['auth/currentUser']) await store.dispatch('auth/fetchCurrentUser')
        const properlyAuth = isAuthenticated && happycabCookie !== null

        if (to.meta.mustBeLogged && properlyAuth) {
          // Ensure proper account creation for clients
          const currentUser = store.getters['auth/currentUser']
          const currentCompanies = store.getters['auth/uElements']('companies')
          if (currentUser.roles.includes('client') && !currentCompanies.length && to.name !== 'signupCompanyComplete') {
            next({ name: 'signupCompanyComplete' })
          } else if (!to.query.redirect) {
            next()
          } else next(base64Decoder(to.query.redirect))
        } else if (to.meta.mustBeLogged && !properlyAuth) {
          // Handle embedOffer
          if (to.name === 'embedOffer' && to.query.userId && to.query.token && to.query.refreshToken) {
            const loginAs = await store.dispatch('auth/loginAs', {
              token: {
                tokenType: 'Bearer',
                userId: to.query.userId,
                accessToken: to.query.token,
                refreshToken: to.query.refreshToken
              },
            })
            if (loginAs) {
              await store.dispatch('auth/fetchCurrentUser')
              next()
            } else {
              next({ name: 'login', query: { redirect: base64Encoder(to) } })
            }
          } else {
            next({ name: 'login', query: { redirect: base64Encoder(to) } })
          }
        } else if (to.meta.mustBeLoggedOut) {
          if (to.name === 'loginAs') {
            await store.dispatch('auth/logout', { ssrCookieInstance: createCookieInstance({ ssrContext }) })
            const loginAs = await store.dispatch('auth/loginAs', {
              token: {
                tokenType: 'Bearer',
                userId: to.params.userId,
                accessToken: to.query.token,
                refreshToken: to.query.refreshToken
              },
              ssrCookieInstance: createCookieInstance({ ssrContext })
            })
            if (loginAs) {
              try {
                stelace.getTokenStore().setTokens(createCookieInstance({ ssrContext }).getHappyCookie())
              } catch (e) {}
              await store.dispatch('auth/fetchCurrentUser')
              next({ name: 'dashboard', query: { loginAs: true } })
            } else {
              await store.dispatch('auth/logout', { ssrCookieInstance: createCookieInstance({ ssrContext }) })
              next()
            }
          } else if (properlyAuth && to.query.redirect) next(base64Decoder(to.query.redirect))
          else if (properlyAuth && to.name === 'login') next({ name: 'dashboard' })
          else {
            await store.dispatch('auth/logout', { ssrCookieInstance: createCookieInstance({ ssrContext }) })
            next()
          }
        } else {
          next()
        }
      } catch (error) {
        await store.dispatch('auth/logout', { ssrCookieInstance: createCookieInstance({ ssrContext }) })
        Router.replace({ name: 'login' })
      }
    })
  }

  return Router
})
